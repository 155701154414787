$cr20: true;
// \brief Pull all parts of the CN-MOBILE theme together into one file
$ano-bold_regular-font: 'Microsoft YaHei', Helvetica, Arial, Sans-Serif;
// Tstar font replaces the Roboto-Mono font
$tstar_font_update: true;
$roboto-mono_regular: roboto-mono_regular, Helvetica, Arial, Sans-Serif;

$opc_update: true;
$password_strengthen: true;
$fonts_update: true;

// 2021 styling refresh.
$cr21: true;

// 2021 styling refresh for SPP.
$cr21_spp: true;

@if $fonts_update == false {
  $ano-bold_regular-font: 'Microsoft YaHei', Helvetica, Arial, Sans-Serif;
}
